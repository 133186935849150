import axios from 'axios'
import { graphql, Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import InPageNav from '../../components/InPageNav'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Section from '../../components/Section'
import SEO from '../../components/SEO'

// TODOS:
// * API key with .env
// * Refactor promises to use async and await

const formatTime = unformattedSeconds => {
  const pad = function(num, size) {
    return ('000' + num).slice(size * -1)
  }
  const time = parseFloat(unformattedSeconds).toFixed(3)
  const hours = Math.floor(time / 60 / 60)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time - minutes * 60)

  return `${pad(hours, 2) === '00' ? '' : `${pad(hours, 2)}:`}${pad(
    minutes,
    2
  )}:${pad(seconds, 2)}`
}
// const VIMEO_ACCESS_TOKEN = 'e692e84a722d10df57877c21ed65b767'
const VIMEO_ACCESS_TOKEN = 'e692e84a722d10df57877c21ed65b767'
const VIMEO_USER_ID = '95585193'
const VIMEO_ALBUM_ID = '5798193'
// const VIMEO_VIDEO_ID = '320320708'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
`

const VideosContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
`

const ResponsiveVideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 10px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VideoDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headline--small {
    margin-bottom: 0;
  }
`

const IIIDFoundation = ({ data }) => {
  const [videoList, setVideoList] = useState({ album: [] })

  const getVideos = async response => {
    try {
      const result = await axios.get(
        `https://api.vimeo.com/users/${VIMEO_USER_ID}/albums/${VIMEO_ALBUM_ID}/videos`,
        {
          headers: {
            Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
          },
        }
      )
      setVideoList({ album: result.data.data })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    function loadVimeoAlbum() {
      getVideos()
    }

    loadVimeoAlbum()
  }, [])

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Training"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/training/" activeClassName="active-page-link">
            Getting Started
          </Link>
          <Link
            to="/training/iiid-foundation/"
            activeClassName="active-page-link"
          >
            IIID Foundation
          </Link>
          <Link to="/training/product/" activeClassName="active-page-link">
            Product
          </Link>
          <Link to="/training/website/" activeClassName="active-page-link">
            Website
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            <VideosContainer>
              {videoList.album.map(video => (
                <li key={video.uri}>
                  <ResponsiveVideoWrapper
                    dangerouslySetInnerHTML={{ __html: video.embed.html }}
                  />
                  <VideoDetails>
                    <h4 className="headline--small">{video.name}</h4>
                    <p className="detail">{formatTime(video.duration)}</p>
                  </VideoDetails>
                </li>
              ))}
            </VideosContainer>
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default IIIDFoundation
